import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import SongItem from "./QueueSongItem";

import * as sessionActionsWS from "../../../actions/sessionActionsWS";

// const style = {
//   fontSize: "18px",
//   lineHeight: 1.6,
//   margin: 0,
//   padding: "0 0 0 5px",
//   counterReset: "item",
//   listStyleType: "none",
//   position: "relative",
//   letterSpacing: "0.5px",
// };

const QueueSongList = ({ songList }) => {

  const sessionCode = useSelector((state) => state.session.Code);
  const dispatch = useDispatch();

  const selectSong = (songId) => {
    dispatch(sessionActionsWS.SendSongId(sessionCode, songId));
  }

  return (
    <ol>
      {songList.map(song => (
        <SongItem
          key={song.Id}
          id={song.Id}
          title={song.Title}
          selectSong={selectSong}
        />
      ))}
    </ol>
  );
}

QueueSongList.propTypes = {
  songList: PropTypes.arrayOf(PropTypes.object),
}

export default QueueSongList;
