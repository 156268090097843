import React from "react";
import LibraryHeader from "./LibraryHeader";
import SongList from "./SongList";
// import * as libraryActionsWS from "../../actions/libraryActionsWS";

const Library = () => {
  return (
    <div className="queue-list-library">
      <LibraryHeader />
      <SongList />
    </div>
  );
}


export default Library;
