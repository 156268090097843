export const LOAD_SONGS_SUCCESS = "LOAD_SONGS_SUCCESS";
export const CREATE_SONG_SUCCESS = "CREATE_SONG_SUCCESS";
export const UPDATE_SONG_SUCCESS = "UPDATE_SONG_SUCCESS";
export const ADD_SONG_SUCCESS = "ADD_SONG_SUCCESS";
export const REMOVE_SONG_SUCCESS = "REMOVE_SONG_SUCCESS";

export const LOAD_SESSION_BEGIN = "LOAD_SESSION_BEGIN";
export const LOAD_SESSION_SUCCESS = "LOAD_SESSION_SUCCESS";

export const CONNECT_SOCKET = "CONNECT_SOCKET";
export const CONNECT_SOCKET_SUCCESS = "CONNECT_SOCKET_SUCCESS";
export const CONNECT_SOCKET_FAIL = "CONNECT_SOCKET_FAIL";

export const SET_SESSION_CODE = "SET_SESSION_CODE";

export const SET_FOLLOW_LYRICS = "SET_FOLLOW_LYRICS";
export const SET_SHOW_CHORDS = "SET_SHOW_CHORDS";

export const JOIN_SESSION = "JOIN_SESSION";
export const JOIN_SESSION_SUCCESS = "JOIN_SESSION_SUCCESS";
export const JOIN_SESSION_FAIL = "JOIN_SESSION_FAIL";

export const SEND_SESSION_SONG_ID = "SEND_SESSION_SONG_ID";
export const SEND_SESSION_SONG_ID_SUCCESS = "SEND_SESSION_SONG_ID_SUCCESS";
export const SEND_SESSION_SONG_ID_FAIL = "SEND_SESSION_SONG_ID_FAIL";

export const SEND_SESSION_VERSE_ID = "SEND_SESSION_VERSE_ID";
export const SEND_SESSION_VERSE_ID_SUCCESS = "SEND_SESSION_VERSE_ID_SUCCESS";
export const SEND_SESSION_VERSE_ID_FAIL = "SEND_SESSION_VERSE_ID_FAIL";

export const SEND = "SEND";
export const SEND_SUCCESS = "SEND_SUCCESS";
export const SEND_FAIL = "SEND_FAIL";

export const SET_SESSION_SONG = "SET_SESSION_SONG";
export const SET_SESSION_VERSE = "SET_SESSION_VERSE";

export const QUEUE_ADD_SONG_ID = "QUEUE_ADD_SONG_ID";
export const QUEUE_ADD_SONG_ID_SUCCESS = "QUEUE_ADD_SONG_ID_SUCCESS";
export const QUEUE_ADD_SONG_ID_FAIL = "QUEUE_ADD_SONG_ID_FAIL";

export const QUEUE_REMOVE_SONG_ID = "QUEUE_REMOVE_SONG_ID";
export const QUEUE_REMOVE_SONG_ID_SUCCESS = "QUEUE_REMOVE_SONG_ID_SUCCESS";
export const QUEUE_REMOVE_SONG_ID_FAIL = "QUEUE_REMOVE_SONG_ID_FAIL";

export const LIBRARY_SEARCH_CHANGE = "LIBRARY_SEARCH_CHANGE";

export const LIBRARY_LOAD_SONGS = "LIBRARY_LOAD_SONGS";
export const LIBRARY_LOAD_SONGS_SUCCESS = "LIBRARY_LOAD_SONGS_SUCCESS";
export const LIBRARY_LOAD_SONGS_FAIL = "LIBRARY_LOAD_SONGS_FAIL";

export const LIBRARY_ADD_SONG_ID = "LIBRARY_ADD_SONG_ID";
export const LIBRARY_REMOVE_SONG_ID = "LIBRARY_REMOVE_SONG_ID";

export const CATALOG_SEARCH_RESULTS = "CATALOG_SEARCH_RESULTS";
export const CATALOG_ADD_SONG_ID = "CATALOG_ADD_SONG_ID";

export const CATALOG_REMOVE_SONG_ID = "CATALOG_REMOVE_SONG_ID";

// export const PREVIEW_ADD_SONG_ID = "PREVIEW_ADD_SONG_ID";
export const SET_PREVIEW_SONG = "SET_PREVIEW_SONG";
export const CLEAR_PREVIEW_SONG = "CLEAR_PREVIEW_SONG";
