import React from "react";
import { useSelector } from "react-redux";
import QueueSongList from "./QueueSongList";
import QueueEmptySongList from "./QueueEmptySongListEmpty";

// import VerseDisplay from "../session/VerseDisplay";

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

const Queue = () => {
  const songList = useSelector((state) =>
    Object.values(state.songs)
      .slice(1)
      .sort((a, b) => (a.Title > b.Title ? 1 : -1))
  );

  return (
    <div className="queue-list-added-songs">
      {songList.length === 0 ? (
        <QueueEmptySongList />
      ) : (
        <React.Fragment>
          <div className="instructional-text">
            {isMobile ? "Swipe to select" : "Double click to select"}
          </div>
          <QueueSongList songList={songList} />
        </React.Fragment>
      )}
    </div>
  );
};

export default Queue;
