import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import Library from "./library/Library";
import Queue from "./queue/Queue";
import Catalog from "./catalog/Catalog";

import VerseDisplay from "./VerseDisplay";
import Navigation from "../common/Navigation";
import LyricsPreviewPopup from "./LyricsPreviewPopup";

const ManagePage = () => {
  const currentVerse = useSelector((state) => {
    let songId = 0;
    if (state.session.SongId != null) {
      songId = state.session.SongId;
    }

    let verseNum = 0;
    if (state.session.VerseNum != null) {
      verseNum = state.session.VerseNum;
    }

    let verse;

    // Check Logic, specifically on Verses
    if (songId && state.songs[songId] && state.songs[songId].Verses[0]) {
      // If verseNum doesn't exist for song, display first verse
      if (
        !state.songs[songId].Verses.find((verse) => verse.Order === verseNum)
      ) {
        verseNum = 0;
      }
      verse = state.songs[songId].Verses.find(
        (verse) => verse.Order === verseNum
      );
    } else {
      verse = state.songs[0].Verses[0];
    }

    return verse;
  });

  return (
    <div className="queue-manage">
      <VerseDisplay verse={currentVerse} />
      <Routes>
        <Route exact path="/queue" element={<Queue />} />
        <Route exact path="/catalog" element={<Catalog />} />
        <Route exact path="/library" element={<Library />} />
      </Routes>
      <Navigation />
      <LyricsPreviewPopup />
    </div>
  );
};

export default ManagePage;
