import { createStore, applyMiddleware, compose } from "redux";
// import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

import * as socketClient from "../vender/SocketClient";
import signalRMiddleware from "../vender/signalRMiddleware";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

// const socketClient = new SocketClient();

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        signalRMiddleware(socketClient)
        // reduxImmutableStateInvariant()
      )
    )
  );
}
