import React from "react";
import { useSelector, useDispatch } from "react-redux";

import * as sessionActions from "../../actions/sessionActions";
import * as sessionActionsWS from "../../actions/sessionActionsWS";
import * as libraryActionsWS from "../../actions/libraryActionsWS";
import * as catalogActionsWS from "../../actions/catalogActionsWS";

import Icon_Recenter_Off from "../common/Icon_Recenter_Off.svg";
import Icon_Recenter_On from "../common/Icon_Recenter_On.svg";
import Icon_Chord_Off from "../common/Icon_Chord_Off.svg";
import Icon_Chord_On from "../common/Icon_Chord_On.svg";
import Icon_Favorite_Off from "../common/Icon_Favorite_Off.svg";
import Icon_Favorite_On from "../common/Icon_Favorite_On.svg";
import Icon_Queue_Off from "../common/Icon_Queue_Off.svg";
import Icon_Queue_On from "../common/Icon_Queue_On.svg";
import Icon_PreviousVerse from "../common/Icon_PreviousVerse.svg";
import Icon_NextVerse from "../common/Icon_NextVerse.svg";

const FollowVersesButton = () => {
  const dispatch = useDispatch();

  const followVerse = useSelector((state) => state.session.FollowVerse);

  const toggleFollowLyrics = () => {
      dispatch(sessionActions.setFollowLyrics(!followVerse));
  };

  return (
    <button className="icon-button" onClick={toggleFollowLyrics}>
      <img src={followVerse ? Icon_Recenter_On : Icon_Recenter_Off} />
    </button>
  );
};

const ToggleShowChordsButton = () => {
  const dispatch = useDispatch();

  const showChords = useSelector((state) => state.session.ShowChords);

  const toggleShowChords = () => {
      dispatch(sessionActions.setShowChords(!showChords));
  };

  return (
    <button className="icon-button" onClick={toggleShowChords}>
      <img src={showChords ? Icon_Chord_On : Icon_Chord_Off} />
    </button>
  );
};

const AddFavoriteSongButton = ({songId}) => {
  const dispatch = useDispatch();
  const librarySongAdd = () => {
    dispatch(catalogActionsWS.SendAddSongToLibrary(1, songId));
  };
  const librarySongRemove = () => {
    dispatch(catalogActionsWS.SendRemoveSongFromLibrary(1, songId));
  };

  const librarySongIds = useSelector((state) =>
    Object.values(state.library.songs).map((s) => s.Id)
  );

  let inLibrary = librarySongIds.filter((l) => l === songId).length > 0;

  return (
    <button
      className="icon-button"
      onClick={inLibrary ? librarySongRemove : librarySongAdd}
    >
      <img src={inLibrary ? Icon_Favorite_On : Icon_Favorite_Off} />
    </button>
  );
};

const AddQueueSongButton = ({songId}) => {
  const dispatch = useDispatch();

  const sessionCode = useSelector((state) => state.session.Code);

  const queueSongAdd = () => {
    dispatch(libraryActionsWS.SendAddSongToQueue(sessionCode, songId));
  };
  const queueSongRemove = () => {
    dispatch(libraryActionsWS.SendRemoveSongFromQueue(sessionCode, songId));
  };

  const queueSongIds = useSelector((state) =>
    Object.values(state.songs).map((s) => s.Id)
  );
  let inQueue = queueSongIds.filter((l) => l === songId).length > 0;

  return (
    <button
      className="icon-button"
      onClick={inQueue ? queueSongRemove : queueSongAdd}
    >
      <img src={inQueue ? Icon_Queue_On : Icon_Queue_Off} />
    </button>
  );
};

const NavigateVersesButton = ({sessionVerseNum}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const goToPreviousVerse = () => {
    sessionVerseNum > 0 &&
      dispatch(
        sessionActionsWS.SendVerseNum(state.session.Code, sessionVerseNum - 1)
      );
  };

  const goToNextVerse = () => {
    console.log(sessionVerseNum);
    let maxVerseNum = state.songs[state.session.SongId].Verses.length - 1;
    sessionVerseNum < maxVerseNum &&
      dispatch(
        sessionActionsWS.SendVerseNum(state.session.Code, sessionVerseNum + 1)
      );
  };

  return (
    <div className="song-display-control">
      <button className="icon-button" onClick={goToPreviousVerse}>
        <img src={Icon_PreviousVerse} />
      </button>
      <button className="icon-button" onClick={goToNextVerse}>
        <img src={Icon_NextVerse} />
      </button>
    </div>
  );
};

export {FollowVersesButton, ToggleShowChordsButton, AddFavoriteSongButton, AddQueueSongButton, NavigateVersesButton};