import React from "react";
import { useSelector } from "react-redux";

import SongItem from "./SongItem";

// import * as catalogActions from "../../actions/catalogActions";
// import * as catalogActionsWS from "../../actions/catalogActionsWS";

// const style = {
//   fontSize: "18px",
//   lineHeight: 1.6,
//   margin: 0,
//   padding: "0 0 0 5px",
//   counterReset: "item",
//   listStyleType: "none",
//   position: "relative",
//   letterSpacing: "0.5px",
// };

const SongList = () => {
  const songList = useSelector((state) => {
    let songList;

    if (state.catalog.searchSongs.length === 0) {
      songList = [{ Id: 0, Title: "No Results", Verses: null }];
    } else {
      songList = state.catalog.searchSongs;
    }
    return songList;
  });

  const librarySongIds = useSelector((state) =>
    state.library.songs.map((s) => s.Id)
  );

  const queueSongIds = useSelector((state) =>
    Object.values(state.songs).map((s) => s.Id)
  );
  
  return (
    songList[0].Title != "No Results" ? 
    <ol>
      {
      songList.map((song) => (
        <SongItem
          key={song.Id}
          songId={song.Id}
          title={song.Title}
          inLibrary={librarySongIds.filter((l) => l === song.Id).length > 0}
          inQueue={queueSongIds.filter((l) => l === song.Id).length > 0}
        />
      ))}
    </ol> :
    <div style={{textAlign: "center", padding: "40px", color: "#c1c1c1"}}>Enter a song title above to search<br />all available songs</div>
  );
};

export default SongList;
