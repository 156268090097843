import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LibraryEmptySongList from "./LibraryEmptySongListEmpty";

import SongItem from "./SongItem";

// import * as libraryActions from "../../actions/libraryActions";
// import * as libraryActionsWS from "../../actions/libraryActionsWS";

// const style = {
//   fontSize: "18px",
//   lineHeight: 1.6,
//   margin: 0,
//   padding: "0 0 0 5px",
//   counterReset: "item",
//   listStyleType: "none",
//   position: "relative",
//   letterSpacing: "0.5px",
// };

const SongList = () => {
  const songListRaw = useSelector((state) => state.library.songs);
  const searchValue = useSelector((state) => state.library.searchValue);

  const [songList, setSongList] = useState([]);

  useEffect(() => {
    const stateSongList = Object.values(songListRaw);

    let songList;

    // this seems like a bad performance thing, maybe move to componentWillReceiveProps
    if (songListRaw.length > 0 && searchValue.length > 0) {
      const myRegEx = new RegExp(searchValue, "i");
      songList = stateSongList.filter((song) => myRegEx.test(song.Title));
    } else {
      songList = stateSongList;
    }
    songList = songList.slice(0, 100);

    setSongList(songList);
  }, [songListRaw, searchValue]);

  // const songList = useSelector((state) => {
  //   const stateSongList = Object.values(state.library.songs);

  //   let songList;

  //   // this seems like a bad performance thing, maybe move to componentWillReceiveProps
  //   if (state.library && state.library.searchValue) {
  //     const myRegEx = new RegExp(state.library.searchValue, "i");
  //     songList = stateSongList.filter(song => myRegEx.test(song.Title));
  //   } else {
  //     songList = stateSongList;
  //   }
  //   songList = songList.slice(0, 100);

  //   return songList;

  // })

  // const songList = useSelector((state) => {
  //   console.log("HERE");
  //   const stateSongList = Object.values(state.library.songs);

  //   let songList;

  //   // this seems like a bad performance thing, maybe move to componentWillReceiveProps
  //   if (state.library && state.library.searchValue.length > 0) {
  //     const myRegEx = new RegExp(state.library.searchValue, "i");
  //     songList = stateSongList.filter((song) => myRegEx.test(song.Title));
  //   } else {
  //     songList = stateSongList;
  //   }
  //   songList = songList.slice(0, 100);

  //   return songList;
  // });

  return songList.length === 0 ? (
    <LibraryEmptySongList />
  ) : (
      <ol>
        {songList.map((song) => (
          <SongItem key={song.Id} songId={song.Id} title={song.Title} />
        ))}
      </ol>
  );
};

export default SongList;
