import baseURL from "../api/BaseURL";
import * as signalR from "@microsoft/signalr";
import * as types from "../actions/actionTypes";

// import signalR from "signalr-client";

// Example conf. You can move this to your config file.
// const host = 'http://localhost:3000';
// const socketPath = '/hubs';

// const connection = new signalR.HubConnection("http://localhost:1850/hubs");

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${baseURL}/hubs`)
  // .configureLogging(signalR.LogLevel.Information)
  .withAutomaticReconnect({
    nextRetryDelayInMilliseconds: (retryContext) => {
      if (retryContext.elapsedMilliseconds < 60000) {
        // If we've been reconnecting for less than 60 seconds so far,
        // wait 5 seconds before the next reconnect attempt.
        return 5000;
      } else if (retryContext.elapsedMilliseconds < 180000) {
        return 10000;
      } else {
        return null;
      }
    },
  }) // this doesn't work by itself due to not resubscribing to listen events
  .build();

export async function connect(dispatch) {
  try {
    await connection.start("webSockets");
    return;
  } catch (err) {
    throw new Error(err);
  }
}

export async function invoke(method, ...data) {
  // console.log(`INVOKE ${method}`);
  // console.log(method);

  try {
    if (connection.state !== signalR.HubConnectionState.Connected) {
      throw new Error("No socket connection");
    }
    const argsArray = Array.prototype.slice.call(arguments); // eslint-disable-line

    const result = await connection.invoke(...argsArray);

    // console.log(
    //   `invocation completed successfully: ${
    //     result === null ? "(null)" : result
    //   }`
    // );
    return;
  } catch (err) {
    console.log(`error: ${err}`);
    throw new Error(err);
  }
}

export async function on(method, fun) {
  // console.log(`ON ${method}`);
  // console.log(method);
  // console.log(fun);

  try {
    if (connection.state !== signalR.HubConnectionState.Connected) {
      throw new Error("No socket connection.");
    }
    connection.on(method, fun);
    return;
  } catch (err) {
    throw new Error("On ERROR");
  }
}

export async function onclose(func) {
  // console.log(`signalr onclose`);
  // console.log(func);

  try {
    connection.onclose(func);
    connection.onreconnecting(func);
    return;
  } catch (err) {
    throw new Error("On ERROR");
  }
}

export async function onreconnected(func) {
  // console.log(`signalr onreconnected`);
  // console.log(func);

  try {
    connection.onreconnected(func);
    return;
  } catch (err) {
    throw new Error("On ERROR");
  }
}

// async (e) => {
//   if (e) {
//     console.log(`CCConnection closed with error: ${e}`);
//     dispatch({ type: types.CONNECT_SOCKET_FAIL });
//   }
// }
