import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LyricsDisplay from "./LyricsDisplay";

export const LyricsPage = () => {
  const song = useSelector((state) => {
    let songId = 0;
    let song;

    if (state.session.SongId != null) {
      songId = state.session.SongId;
    }

    if (songId && state.songs[songId]) {
      song = state.songs[songId];
    } else {
      song = state.songs[0];
    }
    return song;
  });

  return (
    <LyricsDisplay
      song={song}
      followVerses={true}
      toggleChords={true}
      navigateVerses={true}
      addFavoriteSong={true}
    />
  );
};
