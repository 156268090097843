import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function libraryReducer(state = initialState.library, action) {
  switch (action.type) {
    case types.LIBRARY_SEARCH_CHANGE:
      return { ...state, searchValue: action.searchValue };

    case types.LIBRARY_LOAD_SONGS_SUCCESS:
      return { ...state, songs: action.songs };

    case types.LIBRARY_ADD_SONG_ID: // eslint-disable-line
      const addedSong = [...state.songs, action.song];
      const idPositions = addedSong.map(s => s.Id);
      const newSongs = addedSong.filter((item, pos, arr) => {
        return idPositions.indexOf(item.Id) === pos;
      });
      return { ...state, songs: newSongs };

    case types.LIBRARY_REMOVE_SONG_ID: {
      const songs = state.songs.filter(song => song.Id !== action.songId);
      return { ...state, songs }
    }
  

    default:
      return state;
  }
}
