import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useLongPress } from "use-long-press";
import Icon_Queue_Off from "../../common/Icon_Queue_Off.svg";
import Icon_Queue from "../../common/Icon_Queue_On.svg";
import Icon_Favorite from "../../common/Icon_Favorite_On.svg";

import * as libraryActionsWS from "../../../actions/libraryActionsWS";
import * as catalogActionsWS from "../../../actions/catalogActionsWS";
import * as lyricsPreviewActions from "../../../actions/lyricsPreviewActions";

// const styles = {
//   button: {
//     flex: "0 1 70px",
//     width: "70px",
//     padding: "7px",
//     height: "auto",
//     marginLeft: "10px",
//   },
//   title: {
//     flex: "1",
//   },
//   li: {
//     display: "flex",
//     padding: "0",
//     margin: "10px 0",
//   },
// };

const SongItem = ({ songId, title }) => {
  const status = useSelector((state) =>
    state.songs[songId] ? "Unqueue" : "Queue"
  );
  const dispatch = useDispatch();

  const sessionCode = useSelector((state) => state.session.Code);
  const [isScroll, setIsScroll] = useState(false);

  const addSong = () => {
    dispatch(libraryActionsWS.SendAddSongToQueue(sessionCode, songId));
  };
  const removeSong = () => {
    dispatch(libraryActionsWS.SendRemoveSongFromQueue(sessionCode, songId));
  };

  const deleteSong = () => {
    dispatch(catalogActionsWS.SendRemoveSongFromLibrary(1, songId));
  };

  const openPopup = () => {
    if (!isScroll) dispatch(lyricsPreviewActions.setPreviewSong(songId));
  };

  const closePopup = () => {
    dispatch(lyricsPreviewActions.clearPreviewSong());
  };

  const onPress = useLongPress(
    () => {
      openPopup();
    },
    {
      onFinish: (event) => {
        setIsScroll(false);
        closePopup();
      },
      onMove: (event) => {
        setIsScroll(true);
      },
      onCancel: (event) => {
        setTimeout(() => openPopup(), 100);
        setIsScroll(false);
      },
      // cancelOnMovement: true,
      captureEvent: true,
      detect: "both",
    }
  );

  // const onTouchStart = () => {
  //   songPressTimer.current = setTimeout(() => {
  //     openPopup();
  //   }, 500);
  // };

  // const onTouchEnd = () => {
  //   clearTimeout(songPressTimer.current);
  //   closePopup();
  // };

  return (
    <li>
      <a {...onPress}>{title}</a>
      <button
        className="icon-button"
        onClick={status === "Queue" ? addSong : removeSong}
      >
        <img src={status == "Queue" ? Icon_Queue_Off : Icon_Queue} />
      </button>
      <button className="icon-button" onClick={deleteSong}>
        <img src={Icon_Favorite} />
      </button>
    </li>
  );
};

SongItem.propTypes = {
  songId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default SongItem;
