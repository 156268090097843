import React from "react";

const LibraryEmptySongListEmpty = () => {
  return (
    <div className="song-list-empty">
      <div className="song-list-empty-title">EMPTY FAVORITES</div>
      <p className="song-list-empty-instructions">
        Visit <b style={{ color: "black", fontWeight: 900 }}>ALL</b> songs
        <br />
        to mark your
        <br />favorite titles
      </p>
    </div>
  );
};

export default LibraryEmptySongListEmpty;
