import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useLongPress } from "use-long-press";
import Icon_Queue_Off from "../../common/Icon_Queue_Off.svg";
import Icon_Queue from "../../common/Icon_Queue_On.svg";
import Icon_Favorite from "../../common/Icon_Favorite_On.svg";
import Icon_Favorite_Off from "../../common/Icon_Favorite_Off.svg";

import * as catalogActionsWS from "../../../actions/catalogActionsWS";
import * as libraryActionsWS from "../../../actions/libraryActionsWS";
import * as lyricsPreviewActions from "../../../actions/lyricsPreviewActions";

const SongItem = ({ songId, title, inQueue, inLibrary }) => {
  const sessionCode = useSelector((state) => state.session.Code);
  const dispatch = useDispatch();

  const [isScroll, setIsScroll] = useState(false);

  const queueSongAdd = () => {
    dispatch(libraryActionsWS.SendAddSongToQueue(sessionCode, songId));
  };
  const queueSongRemove = () => {
    dispatch(libraryActionsWS.SendRemoveSongFromQueue(sessionCode, songId));
  };

  const librarySongAdd = () => {
    dispatch(catalogActionsWS.SendAddSongToLibrary(1, songId));
  };
  const librarySongRemove = () => {
    dispatch(catalogActionsWS.SendRemoveSongFromLibrary(1, songId));
  };

  const openPopup = () => {
    if (!isScroll) dispatch(lyricsPreviewActions.setPreviewSong(songId));
  };

  const closePopup = () => {
    dispatch(lyricsPreviewActions.clearPreviewSong());
  };

  const onPress = useLongPress(
    () => {
      openPopup();
    },
    {
      onFinish: (event) => {
        setIsScroll(false);
        closePopup();
      },
      onMove: (event) => {
        setIsScroll(true);
      },
      onCancel: (event) => {
        setTimeout(() => openPopup(), 100);
        setIsScroll(false);
      },
      // cancelOnMovement: true,
      captureEvent: true,
      detect: "both",
    }
  );

  return (
    <React.Fragment>
      <li>
        <a {...onPress}>{title}</a>
        <button
          className="icon-button"
          onClick={inQueue ? queueSongRemove : queueSongAdd}
        >
          <img src={inQueue ? Icon_Queue : Icon_Queue_Off} />
        </button>

        <button
          className="icon-button"
          onClick={inLibrary ? librarySongRemove : librarySongAdd}
        >
          <img src={inLibrary ? Icon_Favorite : Icon_Favorite_Off} />
        </button>
      </li>
    </React.Fragment>
  );
};

SongItem.propTypes = {
  songId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  inQueue: PropTypes.bool.isRequired,
  inLibrary: PropTypes.bool.isRequired,
};

export default SongItem;
