import baseURL from "./BaseURL";

class LibraryApi {
  fetchData(url) {
    this.setState({ isLoading: true });
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        this.setState({ isLoading: false });
        return response;
      })
      .then(response => response.json())
      .then(items => this.setState({ items }))
      .catch(() => this.setState({ hasErrored: true }));
  }

  static getSongs() {
    const request = fetch(`${baseURL}/library/GetSongs`);
    return new Promise((resolve, reject) => {
      request
        .then(response => response.json())
        .then(json => {
          // debugger;
          resolve(json);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static addSong(songId) {
    const request = fetch(`${baseURL}/library/AddSong/${songId}`);
    return new Promise((resolve, reject) => {
      request
        .then(response => resolve(response))
        .catch(err => {
          reject(err);
        });
    });
  }

  static removeSong(songId) {
    const request = fetch(`${baseURL}/library/RemoveSong/${songId}`);
    return new Promise((resolve, reject) => {
      request
        .then(response => resolve(response))
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default LibraryApi;
