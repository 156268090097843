import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import * as sessionActionsWS from "../../actions/sessionActionsWS";

// Convert newlines from json into <br />'s
function breakLine(text, showChords) {
  const regex = /(\n)/g;
  return text.split(regex).map((line, index) => {
    return line.match(regex) ? (
      <br key={`key_${index}`} />
    ) : (
      chordLine(line, showChords)
    );
  });
}

function chordLine(text, showChords) {
  const regex = /(\[.*?\])/;
  let widthPreviousChord; // TODO: Not Set. Fixes overlapping chords.

  let splitOutChords = text.split(regex);
  if (splitOutChords.length === 1) return text;
  return splitOutChords.map((line, index) => {
    return line.match(regex) ? (
      showChords && (
        <x-chord key={`key_${index}`}>
          {line.substring(1, line.length - 1)}
        </x-chord>
      )
    ) : (
      <span
        key={`key_${index}`}
        style={{
          minWidth: widthPreviousChord,
          visibility: line.length === 0 ? "hidden" : "visible",
        }}
      >
        {line.length === 0 ? splitOutChords[index - 1] : line}
      </span>
      // style={ line.length === 0 ? {
      //   display: "inline-block",
      //   minWidth: index > 0 && splitOutChords[index - 1].length * 4.75 + "px",
      // } : {}}
    );
  });
}

const VerseDisplay = ({ verse, showChords, verseSelectable }) => {
  const [verseClassName, setVerseClassName] = useState("");

  const dispatch = useDispatch();

  const session = useSelector((state) => state.session);
  // const verseActive = useSelector((state) =>
  //   (state.session.verseNum === verse.Order || session.verseNum === -1));

  useEffect(() => {
    if (session.VerseNum === verse.Order || session.VerseNum === -1 || !verseSelectable) {
      setVerseClassName("active");
    } else {
      setVerseClassName("");
    }
  }, [session.VerseNum, verse.Order]);

  const selectVerse = () => {
    verseSelectable && dispatch(sessionActionsWS.SendVerseNum(session.Code, verse.Order));
  };

  return (
    <div id={`Verse-${verse.Order}`}>
      <h2>{verse.Header}</h2>
      <p // eslint-disable-line
        onClick={selectVerse}
        className={verseClassName}
      >
        {breakLine(verse.Lines, showChords)}
      </p>
    </div>
  );
};

VerseDisplay.propTypes = {
  verse: PropTypes.object.isRequired,
  showChords: PropTypes.bool.isRequired,
};

export default VerseDisplay;
