import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function catalogReducer(state = initialState.catalog, action) {
  switch (action.type) {
    case types.CATALOG_SEARCH_RESULTS:
      return { ...state, searchSongs: JSON.parse(action.songList) };

    case types.CATALOG_ADD_SONG_ID:
      const idPositions = state.searchSongs.map((s) => s.Id);
      const newSongs = [...state.searchSongs];
      newSongs[idPositions.indexOf(action.song.Id)] = action.song;
      // const newSongs = addedSong.filter((item, pos, arr) => {
      //   return idPositions.indexOf(item.Id) === pos;
      // });
      return { ...state, searchSongs: newSongs };

    default:
      return state;
  }
}
