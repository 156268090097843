import * as types from "./actionTypes";
import songApi from "../api/SongApi";

export function loadSongSuccess(songs) {
  return { type: types.LOAD_SONGS_SUCCESS, songs };
}

export function createSongSuccess(song) {
  return { type: types.CREATE_SONG_SUCCESS, song };
}

// add song to queue
export function addSongSuccess(song) {
  return { type: types.ADD_SONG_SUCCESS, song };
}

// export function removeSongSuccess(songId) {
//   return { type: types.REMOVE_SONG_SUCCESS, songId };
// }

export function updateSongSuccess(song) {
  return { type: types.UPDATE_SONG_SUCCESS, song };
}

export function loadSongs(sessionCode) {
  return (dispatch) => {
    return songApi.getAllSongs(sessionCode).then((songs) => {
      dispatch(loadSongSuccess(songs));
    }).catch((error) => {
      throw (error);
    });
  };
}

export function getSong(songId) {
  return (dispatch) => {
    return songApi.getSong(songId).then((song) => {
      dispatch(addSongSuccess(song));
    }).catch((error) => {
      throw (error);
    });
  };
}

export function saveSong(song) {
  return function (dispatch) {
    return songApi.saveSong(song).then(song => { // eslint-disable-line
      song.id ? dispatch(updateSongSuccess(song)) : dispatch(createSongSuccess(song)); // eslint-disable-line
    }).catch((error) => {
      throw (error);
    });
  };
}
