import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function songReducer(state = initialState.songs, action) {
  switch (action.type) {
    case types.LOAD_SONGS_SUCCESS:
      return { ...state, ...action.songs };

    case types.CREATE_SONG_SUCCESS:
      return [...state, Object.assign({}, action.song)];

    case types.UPDATE_SONG_SUCCESS:
      return [
        ...state.filter(song => song.id !== action.song.id),
        Object.assign({}, action.song),
      ];

    case types.ADD_SONG_SUCCESS:
      return { ...state, [action.song.Id]: action.song };

    case types.REMOVE_SONG_SUCCESS: {
      const songs = {};
      for (const songId in state) {
        // eslint-disable-line
        if (state[songId].Id !== action.songId) {
          songs[songId] = state[songId];
        }
      }
      return songs;
    }

    default:
      return state;
  }
}
