import * as types from "./actionTypes";
import sessionApi from "../api/SessionApi";

export const loadSessionBegin = () => {
  return { type: types.LOAD_SESSION_BEGIN };
};
export const loadSessionSuccess = (session) => {
  return { type: types.LOAD_SESSION_SUCCESS, session };
};
export const setSessionSongId = (songId) => {
  return { type: types.SET_SESSION_SONG, songId };
};

export const setSessionCode = (code) => {
  return { type: types.SET_SESSION_CODE, code };
};

export const setFollowLyrics = (followState) => {
  return { type: types.SET_FOLLOW_LYRICS, followState };
};
export const setShowChords = (chordState) => {
  return { type: types.SET_SHOW_CHORDS, chordState };
};

// add Error with isFetching: false

export const loadSession = (code) => {
  return (dispatch) => {
    dispatch(loadSessionBegin());
    return sessionApi
      .getSession(code)
      .then((session) => {
        dispatch(loadSessionSuccess(session));
      })
      .catch((error) => {
        throw error;
      });
  };
};
