import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import CatalogHeader from "./CatalogHeader";
import SongList from "./SongList";
import * as catalogActionsWS from "../../../actions/catalogActionsWS";

const Catalog = () => {
  const dispatch = useDispatch();

  return (
    <div className="queue-list-catalog">
      <CatalogHeader />
      <SongList />
    </div>
  );
}

export default Catalog;
