import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <nav>
      <ul className="queue-navigation">
        <li>
          <NavLink className={({isActive}) => (isActive ? "active" : 'none')} to="/queue">
            Queue
          </NavLink>
        </li>
        <li>
          <NavLink className={({isActive}) => (isActive ? "active" : 'none')} to="/library">
            Mine
          </NavLink>
        </li>
        <li>
          <NavLink className={({isActive}) => (isActive ? "active" : 'none')} to="/catalog">
            All
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

Navigation.propTypes = {
  location: PropTypes.string,
};

export default Navigation;
