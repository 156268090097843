import * as types from "./actionTypes";
import songApi from "../api/SongApi";

export function clearPreviewSong() {
  return { type: types.CLEAR_PREVIEW_SONG };
}

export function setPreviewSong(SongId) {
  return { type: types.SET_PREVIEW_SONG, SongId };
}

// export function addSongSuccess(song) {
//   return { type: types.PREVIEW_ADD_SONG_ID, song };
// }

// export function getSong(songId) {
//   return (dispatch) => {
//     return songApi
//       .getSong(songId)
//       .then((song) => {
//         dispatch(addSongSuccess(song));
//       })
//       .catch((error) => {
//         throw error;
//       });
//   };
// }
