import React from "react";
import PropTypes from "prop-types";
import VerseDisplay from "./VerseDisplay";


const SongDisplay = ({ song, showChords, verseSelectable }) => {


  return (
    <div className="content page-lyric">
      <h3>{song.Title}</h3>
      <div className="lyric">
        {song.Verses && song.Verses.map((verse) => (
          <VerseDisplay
            key={verse.Order}
            verse={verse}
            showChords={showChords}
            verseSelectable={verseSelectable}
          />
        ))}
      </div>
    </div>
  );
};

SongDisplay.propTypes = {
  song: PropTypes.object.isRequired,
  verseSelectable: PropTypes.bool,
};

export default SongDisplay;
