import * as types from "./actionTypes";
import songApi from "../api/SongApi";

export function addSongSuccess(song) {
  return { type: types.CATALOG_ADD_SONG_ID, song };
}

export function getSong(songId) {
  return (dispatch) => {
    return songApi
      .getSong(songId)
      .then((song) => {
        dispatch(addSongSuccess(song));
      })
      .catch((error) => {
        throw error;
      });
  };
}
