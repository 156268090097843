import { combineReducers } from "redux";
import songs from "./songReducer";
import session from "./sessionReducer";
import library from "./libraryReducer";
import catalog from "./catalogReducer";
import lyricsPreview from "./lyricsPreviewReducer";

const rootReducer = combineReducers({
  songs,
  session,
  library,
  catalog,
  lyricsPreview,
});

export default rootReducer;
