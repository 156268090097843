import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LyricsDisplay from "./LyricsDisplay";
import * as catalogActions from "../../actions/catalogActions";

const LyricsPreviewPage = ({ songId }) => {
  const dispatch = useDispatch();

  const librarySongs = useSelector((state) => state.library.songs);
  const catalogSongs = useSelector((state) => state.catalog.searchSongs);

  const [song, setSong] = useState();

  useEffect(() => {
    let song = [...librarySongs, ...catalogSongs].find((s) => s.Id === songId);
    if (!song.Verses) {
      song.Verses = [
        {
          Id: 0,
          Header: "",
          Lines: "Loading...",
          SongId: song.Id,
          Order: 0,
        },
      ];
      dispatch(catalogActions.getSong(songId));
    }
    setSong(song);
  }, [librarySongs, catalogSongs, dispatch, songId]);

  return (
    <>
      {song && (
        <LyricsDisplay song={song} addQueueSong={true} addFavoriteSong={true} />
      )}
    </>
  );
};

export default LyricsPreviewPage;
