import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LyricsPreviewPage from "../lyrics/LyricsPreviewPage";

import * as lyricsPreviewActions from "../../actions/lyricsPreviewActions.js";

const LyricsPreviewPopup = () => {
  //How do you togglePopup from Manage page and how do you pass SongID?
  const dispatch = useDispatch();

  const songId = useSelector((state) => state.lyricsPreview.SongId);

  const close = (event) => {
    dispatch(lyricsPreviewActions.clearPreviewSong());
    console.log(event.type);
  };

  return (
    <React.Fragment>
      {songId && (
        <div className="popup-box" onAuxClick={close}>
          <div className="box">
            <span className="close-icon" onClick={close}>
              x
            </span>
            <LyricsPreviewPage songId={songId} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LyricsPreviewPopup;
