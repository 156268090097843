import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Icon_Queue_On from "../../common/Icon_Queue_On.svg";

import * as libraryActionsWS from "../../../actions/libraryActionsWS";

const unplayed = "rgb(138,193,232)";
const played = "rgb(255,218,147)";

const QueueSongItem = ({ id, title, selectSong }) => {
  const dispatch = useDispatch();

  const song = useSelector((state) => {
    let songId = 0;
    if (state.session.SongId != null) {
      songId = state.session.SongId;
    }
  
    let song;
    // let song = {
    //   "Id": "0",
    //   "Title": "Loading",
    //   "Verses": [
    //     {
    //       "Id": "",
    //       "Header": "",
    //       "Lines": "",
    //       "SongId": "",
    //       "Order": "",
    //     },
    //   ],
    // };
  
    // might not need the middle if
    // potentialy here check if song in store and if not request from API
    if (songId && state.songs[songId]) {
      song = state.songs[songId];
    } else {
      song = state.songs[0];
    }
    return song;
  })
  const session = useSelector((state) => state.session);
  const isFetching = useSelector((state) => state.isFetching);

  const [startX, setStartX] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState(unplayed);

  useEffect(() => {
    // clearTimeout(this.timeout);
    setBackgroundColor(song.Id === id ? played : unplayed);
  }, [song.Id, id]);

  const selectSong2 = () => {
    selectSong(id);
  }

  const updateTouch = (e) => {
    // console.log(e);
  }

  const onDoubleClick = (e) => {
    if(e.changedTouches != null) return;

    selectSong2();
  }

  const updateTouchStart = (e) => {
    // console.log("Start: ");
    setStartX(e.changedTouches[0].pageX);
  }

  const updateTouchMove = (e) => {
    // console.log("Move: ");
    const currentX = e.changedTouches[0].pageX;
    const totalWidth = window.innerWidth;
    let percentSwiped = ((currentX - startX) / totalWidth) * 100;
    // console.log(`Swiped: ${percentSwiped}`);

    if (percentSwiped < 0) {
      percentSwiped = 0;
    } else if (percentSwiped > 60) {
      percentSwiped = 60;
    }

    setBackgroundColor(percentSwiped > 20 ? played : unplayed);

    const distanceMultiplier = (100 - 5) / 60;

    setProgressWidth(percentSwiped * distanceMultiplier);
  }

  const updateTouchEnd = (e) => {
    // console.log("End: ");

    const endX = e.changedTouches[0].pageX;
    const totalWidth = window.innerWidth;
    const percentSwiped = ((endX - startX) / totalWidth) * 100;

    if (percentSwiped > 20) {
      selectSong2();
    }

    setStartX(0);
    setProgressWidth(0);
  }

  const sessionCode = useSelector((state) => state.session.Code);

  const queueSongRemove = () => {
    song.Id != id &&
    dispatch(libraryActionsWS.SendRemoveSongFromQueue(sessionCode, id));
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <li
      className="swipable-list-item"
      onDoubleClick={onDoubleClick}
      onTouchStart={updateTouchStart}
      onTouchMove={updateTouchMove}
      onTouchEnd={updateTouchEnd}
      onTouchCancel={updateTouch}
      style={{position: 'relative'}}
    >
      <div
        className="swipable-list-color-bar"
        style={{ borderLeftColor: song.Id === id ? played : backgroundColor, backgroundColor: backgroundColor, width: `${progressWidth}%` }}
      >
        <a>{title}</a>
      </div>
      <button style={{height: 'auto', padding: '0 10px', position: 'absolute', top: 0, right: 0, width: 'auto', opacity: song.Id != id ? 1 : 0.3}}
        className="icon-button"
        onClick={queueSongRemove}
      >
        <img src={Icon_Queue_On} style={{height: '23px'}} />
      </button>
    </li>
  );
}

QueueSongItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  selectSong: PropTypes.func.isRequired
};

export default QueueSongItem;
