import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Icon_CopyToClipboard from "../common/Icon_CopyToClipboard.svg";

export const WelcomePage = () => {
  const sessionCode = useSelector((state) => state.session.Code);

  const copySessionCode = () => {
    navigator.clipboard.writeText(
      "Join live lyrics at https://sing.at/" + sessionCode + "\nCode: MITKA"
    );
  };

  return (
    <div className="page-welcome">
      <div className="welcome-code">
        <div className="welcome-code-title">Code</div>
        <div className="welcome-code-value">
          {sessionCode}
      </div>
      <button className="icon-button" onClick={copySessionCode}>
            <img src={Icon_CopyToClipboard} />
          </button>
        </div>
      <p className="welcome-greeting">
        {"Welcome to your"}
        <br />
        {"new session!"}
      </p>

      <NavLink to={`/queue`}>
        <button className="button-simple">Start</button>
      </NavLink>
    </div>
  );
};
