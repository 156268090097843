import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as libraryActions from "../../../actions/libraryActions";

const Search = ({ value, onChange }) => {
  return (
    <div className="input-button-group input-button-group-narrow">
      <input value={value} onChange={onChange} />
      <button type="submit">search</button>
    </div>
  );
};

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const AddNewSong = () => (
  <div>
    <button type="submit">Create Song</button>
  </div>
);

let debounceHandler = null;

const LibraryHeader = () => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    debounceHandler = setTimeout(() => {
      dispatch(libraryActions.updateSearch(searchValue));
    }, 600);
    return () => {
      clearTimeout(debounceHandler);
    };
  }, [dispatch, searchValue]);

  return (
    <div className="form-create-or-search">
      <AddNewSong />
      <div className="padded-container">OR</div>
      <Search
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
      />
    </div>
  );
};

export default LibraryHeader;
