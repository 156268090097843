import * as types from "./actionTypes";
import { getSong } from "./songActions";


export function SendAddSongToQueue(sessionCode, songId) {
  const message = [sessionCode, songId];
  return {
    type: "socket",
    types: [
      "SEND",
      "SEND_SUCCESS",
      "SEND_FAIL",
    ],
    promise: socket => socket.invoke("AddSongToQueue", ...message),
  };
}

export function SendRemoveSongFromQueue(sessionCode, songId) {
  const message = [sessionCode, songId];
  return {
    type: "socket",
    types: [
      "SEND",
      "SEND_SUCCESS",
      "SEND_FAIL",
    ],
    promise: socket => socket.invoke("RemoveSongFromQueue", ...message),
  };
}

export function addSongId(songId) {
  // return { type: types.LIBRARY_ADD_SONG_ID, SongId };
  return (dispatch) => {
    dispatch(getSong(songId));
  };
}

export function addSongId2(addSongId, dispatch) {
  return {
    type: "socket",
    types: [types.SEND, types.SEND_SUCCESS, types.SEND_FAIL],
    promise: socket => socket.on("AddSongToQueue", (msg) => {
      const { SongId } = JSON.parse(msg);
      dispatch(addSongId(SongId));
    }),
  };
}

// listen on WS for songs added to Queue
export function listenAddSongId() {
  return (dispatch) => {
    dispatch(addSongId2(addSongId, dispatch));
  };
}

export function removeSongId(songId) {
  return { type: types.REMOVE_SONG_SUCCESS, songId };
}

export function removeSongId2(removeSongId, dispatch) {
  return {
    type: "socket",
    types: [types.SEND, types.SEND_SUCCESS, types.SEND_FAIL],
    promise: socket => socket.on("RemoveSongFromQueue", (msg) => {
      const { SongId } = JSON.parse(msg);
      dispatch(removeSongId(SongId));
    }),
  };
}

export function listenRemoveSongId() {
  return (dispatch) => {
    dispatch(removeSongId2(removeSongId, dispatch));
  };
}
