import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function sessionReducer(state = initialState.session, action) {
  switch (action.type) {
    case types.SET_SESSION_CODE:
      localStorage.setItem("sessionCode", action.code);
      return { ...state, Code: action.code };

    case types.CONNECT_SOCKET_SUCCESS:
      return { ...state, isConnected: true };

    case types.CONNECT_SOCKET_FAIL:
      return { ...state, isConnected: false };

    case types.JOIN_SESSION_SUCCESS:
      return { ...state, joinedSession: true };

    case types.SET_SESSION_SONG:
      return { ...state, SongId: action.SongId, VerseNum: action.VerseNum };

    case types.SET_SESSION_VERSE:
      return { ...state, VerseNum: action.VerseNum };

    case types.LOAD_SESSION_SUCCESS:
      return { ...state, ...action.session };

    case types.SET_FOLLOW_LYRICS:
      return { ...state, FollowVerse: action.followState };

    case types.SET_SHOW_CHORDS:
      return { ...state, ShowChords: action.chordState };

    default:
      return state;
  }
}
