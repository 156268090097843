import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function breakLine(text) {
  const br = React.createElement("br");
  const regex = /(\n)/g;
  return text
    .split(regex)
    .slice(0, 5)
    .map((line, index) => {
      return line.match(regex) ? <br key={`key_${index}`} /> : chordLine(line);
    });
}

function chordLine(text) {
  const regex = /(\[.*?\])/;
  if (text.split(regex).length == 1) return text;
  return text.split(regex).map((line, index) => {
    return line.match(regex) ? (
      <x-chord style={style} key={`key_${index}`}>
        {line.substring(1, line.length - 1)}
      </x-chord>
    ) : (
      line
    );
  });
}

let style = {
  fontSize: "13px",
  top: "-21px",
  lineHeight: "2.5",
};

// __Regex:
// \[(?<chord>.*?)\] /gm
// __Substitute:
// <span class="chord">$2</span>

const VerseDisplay = ({ verse }) => {
  return (
    <div className="queue-verse-preview">
      <NavLink to={"lyrics"}>
        <h3>{verse.Header}</h3>
        <p>{breakLine(verse.Lines)}</p>
      </NavLink>
    </div>
  );
};

VerseDisplay.propTypes = {
  verse: PropTypes.object.isRequired,
};

export default VerseDisplay;
