import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function lyricsPreviewReducer(
  state = initialState.lyricsPreview,
  action
) {
  switch (action.type) {
    case types.SET_PREVIEW_SONG:
      return { ...state, SongId: action.SongId };

    case types.CLEAR_PREVIEW_SONG:
      return { ...state, SongId: null };

    // case types.PREVIEW_ADD_SONG_ID:
    //   return { ...state, Song: action.song };

    default:
      return state;
  }
}
