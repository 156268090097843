// import delay from "./delay";

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

// const songs = [
//   {
//     "Id": 6,
//     "Title": "Mary Had a Little Lamb",
//     "Verses": [
//       {
//         "Id": 13,
//         "Header": "Verse 1",
//         "Lines": "Mary had a little lamb, little lamb,\nlittle lamb, Mary had a little lamb",
//         "SongId": 6,
//         "Order": 0,
//       },
//       {
//         "Id": 14,
//         "Header": "Verse 2",
//         "Lines": "He followed her to school one day,\nschool one day, school one day",
//         "SongId": 6,
//         "Order": 1,
//       },
//       {
//         "Id": 15,
//         "Header": "Verse 3",
//         "Lines": "And so the teacher turned it out,\nturned it out, turned it out,",
//         "SongId": 6,
//         "Order": 2,
//       },
//     ],
//   },
// ];
// const songs = {};

// function replaceAll(str, find, replace) {
//   return str.replace(new RegExp(find, "g"), replace);
// }

// This would be performed on the server in a real app. Just stubbing in.
// const generateId = song => replaceAll(song.title, " ", "-");
import baseURL from "./BaseURL";

class SongApi {
  fetchData(url) {
    this.setState({ isLoading: true });
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        this.setState({ isLoading: false });
        return response;
      })
      .then(response => response.json())
      .then(items => this.setState({ items }))
      .catch(() => this.setState({ hasErrored: true }));
  }

  static getAllSongs(sessionCode) {
    // console.log(`http://localhost:2456/api/lyrics/${sessionCode}`);
    const request = fetch(`${baseURL}/Queue/GetSongs/${sessionCode}`);
    return new Promise((resolve, reject) => {
      request
        .then(response => response.json())
        .then(json => {
          resolve(json);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static getSong(songId) {
    // console.log(`http://localhost:2456/api/lyrics/${sessionCode}`);
    const request = fetch(`${baseURL}/Song/GetSong/${songId}`);
    return new Promise((resolve, reject) => {
      request
        .then(response => response.json())
        .then(json => {
          resolve(json);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default SongApi;
