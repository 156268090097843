import React from "react";

const QueueEmptySongList = () => {
  return (
    <div className="song-list-empty">
      <div className="song-list-empty-title">EMPTY QUEUE</div>
      <p className="song-list-empty-instructions">
        Visit <b style={{ color: "black", fontWeight: 900 }}>ALL</b> songs
        <br />
        to select titles
        <br />
        for your queue
      </p>
      <svg
        className="song-list-empty-instructions-arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="123.973"
        height="222.421"
        viewBox="0 0 123.973 222.421"
      >
        <g
          id="Group_27"
          data-name="Group 27"
          transform="translate(-169.074 -537.114)"
        >
          <path
            id="Path_27"
            data-name="Path 27"
            d="M260.976,4263.094s96.979,49.031,103.991,217.8"
            transform="translate(-91 -3724.196)"
            fill="none"
            stroke="#ffe38f"
            strokeWidth="4"
          ></path>
          <path
            id="Path_28"
            data-name="Path 28"
            d="M354.626,4506l21.237,17.459,16.639-20.792"
            transform="translate(-101.017 -3766.756)"
            fill="none"
            stroke="#ffe38f"
            strokeWidth="4"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default QueueEmptySongList;
