import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink, useNavigate } from "react-router-dom";
import history from "./History";
import hamburger from "./Icon_Menu.svg";

import * as libraryActions from "../../actions/libraryActions";
import * as libraryActionsWS from "../../actions/libraryActionsWS";
import * as catalogActionsWS from "../../actions/catalogActionsWS";

import * as queueActions from "../../actions/songActions";
import * as sessionActionsWS from "../../actions/sessionActionsWS";
import * as sessionActions from "../../actions/sessionActions";

const imageStyle = {
  marginRight: "20px",
};

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector((state) => state.session);

  useEffect(() => {
    const sessionCode = localStorage.getItem("sessionCode");

    if (!sessionCode) {
      navigate("/", { replace: true });
    }

    dispatch(sessionActionsWS.connectWS());
  }, []); //this might not work due to sessionCode not being set yet?

  useEffect(() => {
    console.log("isConnected");

    if (session.isConnected) {
      dispatch(libraryActions.loadSongs());

      dispatch(queueActions.loadSongs(session.Code));
      dispatch(sessionActions.loadSession(session.Code));

      console.log("isConnected: True");

      dispatch(sessionActionsWS.listenSessionDisconnected());

      dispatch(sessionActionsWS.listenSessionSongId());
      dispatch(sessionActionsWS.listenSessionVerseNum());

      dispatch(libraryActionsWS.listenAddSongId());
      dispatch(libraryActionsWS.listenRemoveSongId());

      dispatch(catalogActionsWS.listenAddSongId());
      dispatch(catalogActionsWS.listenRemoveSongId());

      dispatch(catalogActionsWS.listenSearchResults());
    }
  }, [session.isConnected, session.Code, dispatch]);

  useEffect(() => {
    console.log("sessionCode && isConnected");
    if (session.Code && session.isConnected) {
      console.log("sessionCode && isConnected: True");
      dispatch(sessionActionsWS.JoinSession(session.Code));
    }
  }, [session.Code, session.isConnected, dispatch]);

  useEffect(() => {
    console.log("sessionCode");
    if (session.Code) {
      console.log("sessionCode: True");
      dispatch(queueActions.loadSongs(session.Code));
      dispatch(sessionActions.loadSession(session.Code));
    }
  }, [session.Code, dispatch]);

  return (
    <div className="header">
      <div className="header-bar">
        <NavLink to="/queue">
          <img alt="presentation" src={hamburger} style={imageStyle} />
        </NavLink>
        <div className="queue-status">Now Playing</div>
        <div className="queue-join-code">JOIN CODE: {session.Code}</div>
      </div>
      <div className="header-triangle" />
    </div>
  );
};

export default Header;
