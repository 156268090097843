import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import preval from "preval.macro";
import { setSessionCode as reduxSetSessionCode } from "../../actions/sessionActions";

export const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sessionName, setSessionName] = useState("");
  const [localSessionCode, setLocalSessionCode] = useState("");
  const [previousSessionCode] = useState(localStorage.getItem("sessionCode"));

  const onCodeTextChange = (event) => {
    // console.log("typed");
    let value = event.target.value;
    value = value.replace(/[^\w|\d]+/g, "");
    value = value.toUpperCase();

    if (value.length >= 5) {
      // on 5 characters check if sessionCode is active/valid. If it is then join, if it's not then error out (red input?)
      // localStorage.setItem("sessionCode", event.target.value.toUpperCase());
      setLocalSessionCode(value);
      dispatch(reduxSetSessionCode(value));
      setTimeout(() => navigate("lyrics/", { replace: true }), 300);
    }
    setLocalSessionCode(value);
  };
  const onNameTextChange = (event) => {
    return setSessionName(event.target.value);
  };

  return (
    <div className="page-login">
      <div>
        <div className="login-title">Sing At</div>
        <div className="login-subtitle">SESSION</div>
      </div>
      <div className="login-options">
        <input
          id="sessionCode"
          value={localSessionCode}
          onChange={(e) => onCodeTextChange(e)}
          maxLength="5"
          autoComplete="off"
        />

        <NavLink to={`lyrics/${localSessionCode}`}>
          <button className="button-simple" type="submit">
            join
          </button>
        </NavLink>
        <hr />
        <button className="text-button" type="submit">
          Create
        </button>
        {previousSessionCode && (
          <React.Fragment>
            <hr />
            <button className="text-button" type="submit">
              Rejoin: {previousSessionCode}
            </button>
          </React.Fragment>
        )}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "5px",
          left: "5px",
          fontSize: "x-small",
        }}
      >{preval`module.exports = new Date().toLocaleString();`}</div>
    </div>
  );
};
