import * as types from "./actionTypes";
import { getSong } from "./libraryActions";

export function SearchCatalog(searchTerm) {
  return {
    type: "socket",
    types: [
      "SEND",
      "SEND_SUCCESS",
      "SEND_FAIL",
    ],
    promise: socket => socket.invoke("SearchCatalog", searchTerm),
  };
}

export function searchCatalogResults(songList) {
  return { type: types.CATALOG_SEARCH_RESULTS, songList };
}

export function searchCatalogResults2(addSongId, dispatch) {
  return {
    type: "socket",
    types: [types.SEND, types.SEND_SUCCESS, types.SEND_FAIL],
    promise: socket => socket.on("SearchCatalog", (msg) => {
      // const { SongId } = JSON.parse(msg);
      dispatch(searchCatalogResults(msg));
    }),
  };
}

export function listenSearchResults() {
  return (dispatch) => {
    dispatch(searchCatalogResults2(searchCatalogResults, dispatch));
  };
}

export function SendAddSongToLibrary(userId, songId) {
  const message = [userId, songId];
  return {
    type: "socket",
    types: [
      "SEND",
      "SEND_SUCCESS",
      "SEND_FAIL",
    ],
    promise: socket => socket.invoke("AddSongToLibrary", ...message),
  };
}

export function SendRemoveSongFromLibrary(userId, songId) {
  const message = [userId, songId];
  return {
    type: "socket",
    types: [
      "SEND",
      "SEND_SUCCESS",
      "SEND_FAIL",
    ],
    promise: socket => socket.invoke("RemoveSongFromLibrary", ...message),
  };
}

export function addSongId(songId) {
  return (dispatch) => {
    dispatch(getSong(songId));
  };
}

export function addSongId2(addSongId, dispatch) {
  return {
    type: "socket",
    types: [types.SEND, types.SEND_SUCCESS, types.SEND_FAIL],
    promise: socket => socket.on("AddSongToLibrary", (msg) => {
      const { SongId } = JSON.parse(msg);
      dispatch(addSongId(SongId));
    }),
  };
}

export function listenAddSongId() {
  return (dispatch) => {
    dispatch(addSongId2(addSongId, dispatch));
  };
}

export function removeSongId(songId) {
  return { type: types.LIBRARY_REMOVE_SONG_ID, songId };
}

export function removeSongId2(removeSongId, dispatch) {
  return {
    type: "socket",
    types: [types.SEND, types.SEND_SUCCESS, types.SEND_FAIL],
    promise: socket => socket.on("RemoveSongFromLibrary", (msg) => {
      const { SongId } = JSON.parse(msg);
      dispatch(removeSongId(SongId));
    }),
  };
}

export function listenRemoveSongId() {
  return (dispatch) => {
    dispatch(removeSongId2(removeSongId, dispatch));
  };
}
