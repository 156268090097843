import * as types from "./actionTypes";
// import sessionApi from "../api/SessionApi";

export function loadSessionBegin() {
  return { type: types.LOAD_SESSION_BEGIN };
}
export function loadSessionSuccess(session) {
  return { type: types.LOAD_SESSION_SUCCESS, session };
}

export function connectWS() {
  return {
    type: "socket",
    types: [
      types.CONNECT_SOCKET,
      types.CONNECT_SOCKET_SUCCESS,
      types.CONNECT_SOCKET_FAIL,
    ],
    promise: (socket) => socket.connect(),
  };
}

export function disconnectWS() {
  return {
    type: "socket",
    types: [
      "DISCONNECT_SOCKET",
      "DISCONNECT_SOCKET_SUCCESS",
      "DISCONNECT_SOCKET_FAIL",
    ],
    promise: (socket) => socket.stop(),
  };
}

export function JoinSession(sessionCode) {
  return {
    type: "socket",
    types: [
      types.JOIN_SESSION,
      types.JOIN_SESSION_SUCCESS,
      types.JOIN_SESSION_FAIL,
    ],
    promise: (socket) => socket.invoke("JoinSession", sessionCode),
  };
}

// export function SendSongId(sessionCode, SongId) {
//   return {
//     type: types.SET_SESSION_SONG,
//     SongId,
//     VerseNum: -1,
//   };
// }

export function SendSongId(sessionCode, songId) {
  const message = [sessionCode, songId];
  return {
    type: "socket",
    types: [
      types.SEND_SESSION_SONG_ID,
      types.SEND_SESSION_SONG_ID_SUCCESS,
      types.SEND_SESSION_SONG_ID_FAIL,
    ],
    promise: (socket) => socket.invoke("UpdateSongId", ...message),
  };
}

export function SendVerseNum(sessionCode, verseNum) {
  const message = [sessionCode, verseNum];
  return {
    type: "socket",
    types: [
      types.SEND_SESSION_VERSE_ID,
      types.SEND_SESSION_VERSE_ID_SUCCESS,
      types.SEND_SESSION_VERSE_ID_FAIL,
    ],
    promise: (socket) => socket.invoke("UpdateVerseNum", ...message),
  };
}

export function setSongId(SongId, VerseNum) {
  return { type: types.SET_SESSION_SONG, SongId, VerseNum };
}

export function setSongId2(setVerseNum, dispatch) {
  return {
    type: "socket",
    types: [types.SEND, types.SEND_SUCCESS, types.SEND_FAIL],
    promise: (socket) =>
      socket.on("UpdateSongId", (msg) => {
        const { SongId, VerseNum } = JSON.parse(msg);
        dispatch(setSongId(SongId, VerseNum));
      }),
  };
}

export function listenSessionSongId() {
  return (dispatch) => {
    dispatch(setSongId2(setSongId, dispatch));
  };
}

export function setVerseNum(VerseNum) {
  return { type: types.SET_SESSION_VERSE, VerseNum };
}

export function setVerseNum2(setVerseNum, dispatch) {
  return {
    type: "socket",
    types: [types.SEND, types.SEND_SUCCESS, types.SEND_FAIL],
    promise: (socket) =>
      socket.on("UpdateVerseNum", (msg) => {
        const { VerseNum } = JSON.parse(msg);
        dispatch(setVerseNum(VerseNum));
      }),
  };
}

export function listenSessionVerseNum() {
  return (dispatch) => {
    dispatch(setVerseNum2(setVerseNum, dispatch));
  };
}

export function setDisconnected2(dispatch) {
  return {
    type: "socket",
    types: [types.SEND, types.SEND_SUCCESS, types.SEND_FAIL],
    promise: (socket) => {
      socket.onclose(async (e) => {
        dispatch({ type: types.CONNECT_SOCKET_FAIL });
      });

      // THE FOLLOWING IS BAD? IT REEALLY SHOULD HAVE IT'S OWN I THINK
      // I'm not sure what the behavior here is with returning this one
      return socket.onreconnected(async () => {
        dispatch({ type: types.CONNECT_SOCKET_SUCCESS });
      });
    },
  };
}

export function listenSessionDisconnected() {
  return (dispatch) => {
    dispatch(setDisconnected2(dispatch));
  };
}
