import * as types from "./actionTypes";
import libraryApi from "../api/LibraryApi";
import songApi from "../api/SongApi";

// eslint-disable-next-line import/prefer-default-export
export function updateLibrarySearch(searchValue) {
  return { type: types.LIBRARY_SEARCH_CHANGE, searchValue };
}

export function loadSongSuccess(songs) {
  return { type: types.LIBRARY_LOAD_SONGS_SUCCESS, songs };
}


export function updateSearch(searchValue) {
  return (dispatch) => {
    dispatch(updateLibrarySearch(searchValue));
  };
}

export function loadSongs() {
  return (dispatch) => {
    return libraryApi.getSongs().then((songs) => {
      dispatch(loadSongSuccess(songs));
    }).catch((error) => {
      throw (error);
    });
  };
}


export function addSongSuccess(song) {
  return { type: types.LIBRARY_ADD_SONG_ID, song };
}

export function getSong(songId) {
  return (dispatch) => {
    return songApi.getSong(songId).then((song) => {
      dispatch(addSongSuccess(song));
    }).catch((error) => {
      throw (error);
    });
  };
}
