export default {
  songs: {
    0: {
      Id: 0,
      Title: "Loading",
      Verses: [
        {
          Id: 0,
          Header: "Initial State",
          Lines: "Select a Song",
          SongId: 0,
          Order: 0,
        },
      ],
    },
  },
  session: {
    SongId: 0,
    VerseNum: 0,
    Code: localStorage.getItem("sessionCode"),
    FollowVerse: true,
    ShowChords: true,
  },
  library: {
    searchValue: "",
    songs: [
      {
        Id: 0,
        Title: "No Songs in Library",
        Verses: [
          {
            Id: 0,
            Header: "",
            Lines: "",
            SongId: 0,
            Order: 0,
          },
        ],
      },
    ],
  },
  catalog: {
    searchValue: "",
    searchSongs: [],
  },
  lyricsPreview: {
    SongId: null,
  },
};
