import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/common/Header";
import { HomePage } from "./components/home/HomePage";
import { WelcomePage } from "./components/home/WelcomePage";
import { LyricsPage } from "./components/lyrics/LyricsPage";
import ManagePage from "./components/manage/ManagePage";
import "./App.css";

function App(props) {
  return (
    <div id="app">
      <div id="app-body">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/lyrics" element={<LyricsPage />} />

          <Route path="/*" element={<ManagePage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
