import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SongDisplay from "./SongDisplay";
import {
  FollowVersesButton,
  ToggleShowChordsButton,
  AddFavoriteSongButton,
  AddQueueSongButton,
  NavigateVersesButton,
} from "./LyricControlButtons";

export const LyricsDisplay = ({ song, followVerses, toggleChords, addFavoriteSong, addQueueSong, navigateVerses }) => {
  const sessionVerseNum = useSelector((state) => state.session.VerseNum);
  const followVerse = useSelector((state) => state.session.FollowVerse);
  const showChords = useSelector((state) => state.session.ShowChords);

  // const isFetching = useSelector((state) => state.isFetching);

  useEffect(() => {
    // clearTimeout(this.timeout);
    if (
      followVerse &&
      sessionVerseNum >= 0 &&
      document.getElementById(`Verse-${sessionVerseNum}`)
    )
      smoothScroll(getScrollOffset(sessionVerseNum), 200);
  }, [sessionVerseNum, followVerse]);

  useEffect(() => {
    return function cleanup() {
      // clearTimeout(this.timeout);
    };
  });

  const getScrollOffset = (sessionVerseNum) => {
    // should we check if verse div is greater than viewport,
    // and if so, don't center in middle but place start of verse at the top
    // so that the first line is not out of view?
    const element = document.getElementById(`Verse-${sessionVerseNum}`);
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop =
      elementRect.top + document.getElementById("root").scrollTop;
    const elementTop2Middle = elementRect.height / 2;

    const absoluteElementMiddle = absoluteElementTop + elementTop2Middle;

    const middle = absoluteElementMiddle - window.innerHeight / 2;
    if (window.innerHeight < elementRect.height) { // if verse is longer than window, scroll to top of verse (instead of centering verse on page)
      return absoluteElementTop;
    } else return middle;
  };

  const smoothScroll = (elementY, duration) => {
    const rootElement = document.getElementById("root");
    const startingY = rootElement.scrollTop;
    console.log("startingY" + startingY);
    const diff = elementY - startingY;
    let start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed miliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);

      rootElement.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  };

  // const doScrolling = (elementY, duration) => {
  //   const startingY = window.pageYOffset;
  //   // If element is close to page's bottom then window will scroll only to some position above the
  //   const targetY =
  //     document.body.scrollHeight - elementY < window.innerHeight
  //       ? document.body.scrollHeight - window.innerHeight
  //       : elementY;
  //   const diff = targetY - startingY;
  //   // Easing function: easeInOutCubic
  //   // From: https://gist.github.com/gre/1650294
  //   const easing = function (t) {
  //     return t < 0.5
  //       ? 4 * t * t * t
  //       : (t - 1) * ((2 * t - 2) * (2 * t - 2)) + 1;
  //   };
  //   let start;

  //   if (!diff) return;

  //   // Bootstrap our animation - it will get called right before next frame shall be rendered.
  //   window.requestAnimationFrame(function step(timestamp) {
  //     if (!start) start = timestamp;
  //     // Elapsed miliseconds since start of scrolling.
  //     const time = timestamp - start;
  //     // Get percent of completion in range [0, 1].
  //     let percent = Math.min(time / duration, 1);
  //     // Apply the easing.
  //     // It can cause bad-looking slow frames in browser performance tool, so be careful.
  //     percent = easing(percent);

  //     window.scrollTo(0, startingY + diff * percent);

  //     // Proceed with animation as long as we wanted it to.
  //     if (time < duration) {
  //       window.requestAnimationFrame(step);
  //     }
  //   });
  // };

  return (
    <div className="song-display">
      <div className="song-display-settings">
        {followVerses && <FollowVersesButton />}
        {toggleChords && <ToggleShowChordsButton />}
        {addFavoriteSong && <AddFavoriteSongButton songId={song.Id} />}
        {addQueueSong && <AddQueueSongButton songId={song.Id} />}
      </div>

      <SongDisplay
        song={song}
        showChords={showChords}
        verseSelectable={followVerses}
      />
      {navigateVerses && (
        <NavigateVersesButton sessionVerseNum={sessionVerseNum} />
      )}
    </div>
  );
};

export default LyricsDisplay;
